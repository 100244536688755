/*
 * Copyright 2017 General Code
 */

if (!window.generalcode) {
  window.generalcode = {};
}

var selectedGuids = {};

$(window).load(function () {

  var jqBody = $('body');
  var emailTool = $('#sendButton');
  var customerName = jqBody.data('customerName');
  var bodyCustId = jqBody.data('custId') + "";
  var sidebarLabel = jqBody.data('sidebarLabel');
  var bodyGuid = jqBody.data('guid') + "";
  var user = jqBody.data('user');
  var codeId = jqBody.data('codeId');
  var codeIdIsNotCurrentCodeId = (codeId !== jqBody.data('currentCodeId'));
  var codePrefix = codeIdIsNotCurrentCodeId ? codeId + '/' : '';

  var toolbar = $('#toolbar');
  var toolbarHeight = toolbar.height();
  var warningsHeight = $('#warnings').height();
  var gps = $('#gps');
  var gpsHeight = gps.height();
  var titleArea = $('#titleArea');
  var chapterString = jqBody.data('chapterString') || emailTool.data('chapter-string');
  var eventService = new EventService();
  var menubarHeight = $('#menubar').height();
  var footer = $('#disclaimer');
  var bigTables = $('.codeTable:not(.landTable) table').filter(function () {
    //if the table has more than 30 rows then float it's header
    return $("tbody", this).children().size() > 30 && $("thead", this).children().size() > 0;
  });

  var baseTitle = null;

  var showOverlay = function () {
    jqBody.addClass("showOverlay");
  };
  var hideOverlay = function () {
    jqBody.removeClass("showOverlay");
  };

  if (jqBody.data("legrefLinkEnabled") === true) {
    var createLegrefLink = function ($legref, className, reference) {
      var $legrefLink = $("<a />");
      $legrefLink.addClass(className);
      $legrefLink.attr("target", "_blank");
      $legrefLink.attr("href", "/" + bodyCustId + "/laws/" + reference + ".pdf");
      $legrefLink.text($legref.text());
      $legref.replaceWith($legrefLink);
    };
    $(".legref").each(function () {
      if ($(this).data("ref")) {
        var $legref = $(this);
        var reference = $legref.data("ref");
        var $replaceElement = ($legref.children(".loclaw").length > 0 ? $legref.children(".loclaw") : $legref);
        var classNames = ["xref"];
        $replaceElement.children().addBack().each(function () {
          var childClasses = $(this).attr("class") ? $(this).attr("class").split(/\s+/) : [];
          for (var i = 0; i < childClasses.length; i++) {
            if (classNames.indexOf(childClasses[i]) === -1) {
              classNames.push(childClasses[i]);
            }
          }
        });
        createLegrefLink($replaceElement, classNames.join(" "), reference);
      }
    });
  }

  $(".attachmentslink").click(function(e) {
    e.preventDefault();
    var href = $(this).attr("href");
    var guid = href.substring((href.lastIndexOf("/") + 1), href.indexOf("#"));
    var url = "/api/" + bodyCustId + "/code/" + codeId + "/attachments/" + guid;
    $.ajax({
      type: "GET",
      url: url,
      success: function (response) {
        var $attachmentsModal = $('<div class="attachmentsModalContent"/>');
        var $attachmentsContainer = $('<div class="attachmentsContainer"/>');
        if (response.attachments && response.attachments.length > 0) {
          for (var i = 0; i < response.attachments.length; i++) {
            var attachment = response.attachments[i];
            var attachmentName = attachment.filename.endsWith("." + attachment.extension) ?
              attachment.filename.substring(0, attachment.filename.length - (attachment.extension.length + 1)) :
              attachment.filename;
            var attachmentTitle = "";
            if(attachment.title) {
              attachmentTitle = attachment.title;
            }
            var $attachment = $('<a class="nonxml" target="_blank" />');
            $attachment.attr("href", ("/attachment/" + bodyCustId + "/" + attachmentName + "." + attachment.extension));
            if(attachmentTitle !== "") {
              $attachment.html(attachmentTitle + '<img src="/images/pdf.gif"/>');
            } else {
              $attachment.html(attachmentName + '<img src="/images/pdf.gif"/>');
            }
            $attachmentsContainer.append($attachment);
          }
        } else {
          $attachmentsContainer.text("There are no attachments under " + response.content.label + " " +
            response.content.number + " " + response.content.title);
        }
        $attachmentsModal.append($attachmentsContainer);
        $.modal({
          modalClass: "attachmentsModal",
          title: {
            content:
              "<div class='customerTitleColor'>" +
                "<span class='titleNumber'>" +
                  response.content.label + " " + response.content.number +
                "</span>" +
                "<span class='titleTitle'>" +
                  response.content.title +
                "</span>" +
              "</div>" +
              "<div class='attachmentsTitle'>ATTACHMENTS</div>",
            titleClass: "attachmentsModalTitle"
          },
          content: $attachmentsModal
        });
      },
      error: function () {
        window.open(href, "_blank");
      }
    });
  });

  var contentPreviewEnabled = jqBody.data('contentPreviewPermission');
  if (contentPreviewEnabled) {

    document.documentElement.addEventListener("mousemove", function (e) {
      document.documentElement.style.setProperty('--mouse-x', e.clientX + "px");
      document.documentElement.style.setProperty('--mouse-y', e.clientY + "px");
    });

    var $contentPreview = $('<div id="content-preview" />');
    jqBody.append($contentPreview);
    var hoverDelay = 750;
    var hoverTimeout;
    $("a.xref,a.nonxml").hover(function (e) {
      var $xrefLink = $(this);
      hoverTimeout = setTimeout(function () {
        if ($xrefLink[0].ownerDocument === document) {
          var url = new URL(window.location.href);
          url.search = "";
          if ($xrefLink.attr("href")) {
            var newUrl = new URL(url.origin + "/" + $xrefLink.attr("href"));
            url.pathname = newUrl.pathname;
            url.hash = newUrl.hash;
          } else {
            var path = url.pathname.substring(1);
            var pathPrefix = "";
            if (path.indexOf("/") !== -1) {
              pathPrefix = path.substring(0, path.indexOf("/"));
            }
            var guid = $xrefLink.attr("guid") || $xrefLink.data("guid");
            url.pathname = pathPrefix + guid;
            url.hash = guid;
          }
          url.searchParams.set("content-only", "true");
          var $previewFrame = (url.pathname.endsWith(".pdf")) ?
            $('<object data="' + url.href + '" type="application/pdf"><div>No online PDF viewer installed</div></object>') :
            $('<iframe allowTransparency="true" frameborder="0" scrolling="no" src="' + url.href + '"></iframe>');

          if (e.clientX < ($(window).width() / 2)) {
            $contentPreview.removeClass("left").addClass("right");
          } else {
            $contentPreview.addClass("left").removeClass("right");
          }
          if (e.clientY < ($(window).height() / 2)) {
            $contentPreview.removeClass("top").addClass("bottom");
          } else {
            $contentPreview.addClass("top").removeClass("bottom");
          }
          $contentPreview.empty();
          $contentPreview.append($previewFrame);
          $contentPreview.show();
        }
      }, hoverDelay);
    }, function() {
      if ($contentPreview) {
        $contentPreview.empty();
        $contentPreview.hide();
      }
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      }
    });
  }

  if ($.floatThead) {
    bigTables.floatThead({
      top: function () {
        var isEmptySelectedGuids = $.isEmptyObject(selectedGuids) || (Object.keys(selectedGuids).length === 1 && selectedGuids.hasOwnProperty(bodyGuid));
        var isToolbar = !isEmptySelectedGuids || !$.isEmptyObject(selection);
        if ($(this).parents('.landTable').size() === 0) {
          var jqBodyTop = jqBody.position().top + menubarHeight;
          var titleHeight = $('#titleBox').height() ? $('#titleBox').height() : titleArea.height();
          return warningsHeight + titleHeight + (isToolbar ? toolbarHeight : gpsHeight) + jqBodyTop + 2;
        } else {
          return 0;
        }
      },
      zIndex: 1,
      copyAttributes: ['tabstyle'],
      autoReflow: true
    });
    gps.on('gps.heightChange', function() {
      //the gps changed height because of scrolling, update the variable used everywhere and reflow thead tables
      gpsHeight = gps.height();
      bigTables.trigger("reflow");
    });
  } else {
    //we are missing thead for some reason but recalculating the gps height is still good
    gps.on('gps.heightChange', function () {
      gpsHeight = gps.height();
    });
  }

  if (typeof $.cookie === 'function') {
    if ($.cookie('mode') === 'touch') {
      window.location.reload(true);
    }

    if (bodyCustId && !user && jqBody.data('subscriptionQuestion')) {
      var custIds = $.cookie('custIds');
      if (!(custIds) || custIds === '') {
        custIds = bodyCustId;
      } else if (custIds.indexOf(bodyCustId) === -1) {
        custIds = custIds + ',' + bodyCustId;
      }
      $.cookie('custIds', custIds, {expires: 365});

      if (custIds.indexOf(',') !== custIds.lastIndexOf(',')) {
        var shown = $.cookie('marketingShown');
        if (!shown) {
          $.cookie('marketingShown', true, {expires: 365});

          eventService.logEvent(EventCategory.SUBSCRIPTION, EventName.SUBSCRIPTION_QUESTION_ASKED, bodyCustId, {custIds: custIds});
          $.modal({
            modalClass: 'modalError', content: jqBody.data('subscriptionQuestion'), buttons: [{
              title: 'Yes', handler: function () {
                window.open(jqBody.data('subscriptionPurchaseUrl'), '_blank');
                $.closeModal();
              }
            }, {
              title: 'No', buttonClass: 'modalClose', handler: function () {
                eventService.logEvent(EventCategory.SUBSCRIPTION, EventName.SUBSCRIPTION_QUESTION_DECLINED, bodyCustId, {custIds: custIds});
              }
            }]
          });
        }
      }
    }
  }

  var jumpToHash = function (e, hash, hasHighlight, ignore) {

    if (!hash) {
      hash = $(this).attr('href');
    }
    if (hash.charAt(0) === '/') {
      if (hash.indexOf('#') !== -1) {
        hash = hash.substring(hash.indexOf('#') + 1);
      } else {
        hash = '#' + hash.substring(1);
      }
    }

    var origHash = hash;
    var hashEl;
    if (hash.indexOf("notes") !== -1) {
      hashEl = $('.notes');
    } else if (hash.indexOf("laws") !== -1) {
      hashEl = $('.lawTableContainer');
    } else if (hash.indexOf("question") !== -1) {
      // Try to locate the question container
      hashEl = $(hash);
      // If the question container can't be found, scroll to the structure container instead
      if (hashEl.length === 0) {
        // Remove "#question-" from the beginning
        var structureGuid = hash.substring(10);
        // Remove the question id from the end
        structureGuid = structureGuid.substring(0, structureGuid.indexOf('-'));
        // Set the hash to the structure id
        hash = '#' + structureGuid;
        hashEl = $(hash);
      }
    } else {

      hash = (hash + "").toUpperCase();
      if (hash && hash.indexOf('#') === 0 && hash.indexOf('/') === -1 && hash.substring(1).toUpperCase() !== bodyGuid.toUpperCase()) {
        //Do we have the facebook "safety hash"
        if (hash === '#_=_') {
          //remove it and otherwise do nothing
          var noHashUrl = (window.location + "").replace(/\??#_=_$/, '');
          var startTitle = $('title').text();
          History.replaceState(null, startTitle, noHashUrl);
          return;
        }
        hashEl = $(hash);
        if (!hashEl.length) {
          hashEl = $(origHash);
          hash = origHash;
        }

        if (!hashEl.length && jqBody.hasClass('contentPage') && hash.indexOf("note") === -1 &&
          hash.indexOf("_LAST_DL_ENTRY") === -1 && window.location.pathname.substring(1) !== hash.substring(1)) {
          window.location.pathname = hash.substring(1);
        }
      }
    }
    var hashContent = $(hash + "_content");
    var highlight;
    // If there is a highlight and it's not in the title and it's not a note, we want to scroll to the highlight
    if (hashEl && hasHighlight && (!hashEl.hasClass('barTitle') || !hashEl.find('.highlight').length) && hash.indexOf("note") === -1) {
      highlight = hashEl.add(hashContent).find('.highlight');
      if (highlight.length) {
        hashEl = highlight;
      }
    }

    if (hashEl) {

      // Focus on the focusable thing for screen readers
      var focusableSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
      var focusableEl = $(hashEl).find(focusableSelector).addBack(focusableSelector);
      if (focusableEl.size() > 0) {
        focusableEl.focus();
      }

      var offset = hashEl.offset();
      if (offset) {

        var hashNotes = $("#notes-" + hash.substring(1));
        var stickyTableHeader = hashEl.closest("table").siblings(".floatThead-container");
        var calculateOffsetVal = function() {
          offset = hashEl.offset();

          var jqBodyTop = jqBody.position().top + menubarHeight;
          var offsetHeight = gpsHeight + jqBodyTop;
          if (toolbar.css('position') === 'fixed') {
            offsetHeight = toolbarHeight + jqBodyTop;
          }

          var questionFiltersSticky = $('#questionFiltersContainer .sticky');
          if (questionFiltersSticky.length > 0) {
            offsetHeight = offsetHeight + questionFiltersSticky.outerHeight();
          }

          var blackBar = $('#titleBox');
          var offsetVal = offset.top - (offsetHeight + blackBar.height() + (stickyTableHeader.length > 0 ? stickyTableHeader.height() : 0));

          if (!hasHighlight || !highlight || !highlight.length) {
            if (hashContent.offset()) {
              offsetVal = hashContent.offset().top - (offsetHeight + blackBar.height());
            }
            if (hashNotes && hashNotes.length > 0 && hashNotes.children().length > 0) {
              offsetVal = hashNotes.offset().top - (offsetHeight + blackBar.height());
            }
          }
          return offsetVal;

        };
        $('body').animate({scrollTop: (calculateOffsetVal() + document.body.scrollTop) / 2}, {duration: 300, easing: 'linear'}).promise().done(function() {
          requestAnimationFrame(function() {
            $('html, body').animate({scrollTop: calculateOffsetVal()},{duration: 200, easing: 'linear'}).promise().done(function () {
              requestAnimationFrame(function () {
                $('html, body').animate({scrollTop: calculateOffsetVal()}, {duration: 100, easing: 'linear'});
              });
            });
          });
        });
        if (!baseTitle) {
          baseTitle = $('title').text();
        }
        if (hashEl.hasClass('footnote') || hashEl.hasClass('footnoteref')) {
          if (e) {
            e.preventDefault();
            e.stopPropagation();
          }
          hashEl.css('background-color', '#cc7700').animate({backgroundColor: $.Color('#ddd')}, 2000, 'swing', function () {
            hashEl.css('background-color', 'transparent');
          });
        } else {
          var url = hash.substring(1) + window.location.search;
          if ($(this).hasClass('year')) {
            url = window.location.pathname + $(this).attr('href');
          }
          if (!ignore) {
            window.History.pushState(null, baseTitle + ": " + hashEl.text(), url);
          }
        }

        if (e) {
          e.preventDefault();
        }
      }
    }
  };
  window.generalcode.scrollToHash = function() {
    jumpToHash(null, location.hash, new URLSearchParams(location.search).get('highlight'), true);
  };

  $('.litem_number,.defitem_number,.termLink,.titleLink').on('click', function (e) {
    if (!jqBody[0].dataset.ia2Url) {
      return;
    }
    if (e.shiftKey) {
      e.preventDefault();
      e.stopImmediatePropagation();
      var href = $(this).attr("href");
      if (href.indexOf("#") >= 0) {
        createManuscript(href.substring(href.indexOf("#")+1));
      } else if (href.charAt(0) === "/") {
        createManuscript(href.substring(1));
      }
    }
  });

  var location = window.location;
  var pathname = location.pathname.replace(codePrefix ,"");
  if (location.hash) {
    jumpToHash(null, location.hash, new URLSearchParams(location.search).get('highlight'), true);

    // If we got a real url for something deeper than just a guid we don't want to jump otherwise jump to the guid
  } else if (pathname.substring(1) !== bodyGuid && pathname.substring(1).indexOf("/") === -1) {
    jumpToHash(null, '#' + pathname.substring(1), new URLSearchParams(location.search).get('highlight'), true);
  }
  $('#content, #titleArea, #drawer, #page-content').on('click', '.titleLink, .xref, .footnoteref, .footnoterefnum,.year', jumpToHash);

  var getTitle = function(guid) {
    var title = "";
    var $titleElement = $('#' + guid + ',#' + guid + '_title');
    if ($titleElement.length > 0) {
      if ($titleElement.hasClass('litem')) {
        var $titleNumber = $titleElement.find('.litem_number');
        if ($titleNumber.length > 0) {
          title = '§ ' + $titleNumber.attr('title');
        }
        var $title = $titleElement.find('>.title,>.litem_content>.title');
        if ($title.length > 0 && $title.text().trim()) {
          title += ' ' + $title.text();
        }
      } else if ($titleElement.hasClass('defitem')) {
        var $definitionNumber = $titleElement.find('> .defitem_number');
        if ($definitionNumber.length > 0) {
          title += $definitionNumber.text() + " ";
        }
        var $definitionTerm = $titleElement.find('.term');
        if ($definitionTerm.length > 0) {
          title += $definitionTerm.text();
        }
      } else if ($titleElement.data('full-title')) {
        title = $titleElement.data('full-title');
      } else {
        title = $titleElement.text();
      }
    } else if (bodyGuid === guid) {
      title = jqBody.data('pageString') ? jqBody.data('pageString')
        : (jqBody.data('longPageString') ? jqBody.data('longPageString') : jqBody.data('customerName'));
    }
    return title ? title.replace('\\', '/') : '';
  };

  var selectionClick = function (pseudo) {
    var selectionParent = $(this);
    if (pseudo !== true) {
      selectionParent = selectionParent.parent();
    }
    var shouldBeSelected = !selectionParent.hasClass('selected');
    var shouldStickyHeader = $(".selected").length === 0 && shouldBeSelected;
    $('.' + selectionParent.data('guid')).each(function (index, value) {
      if (shouldBeSelected) {
        $(value).addClass('selected');
      } else {
        $(value).removeClass('selected');
      }
    });

    var subSectionId = 'subSectionOf-' + $(this).parent().data('guid');
    var subSection = $('#' + subSectionId);
    if(subSection.length > 0) {
      if (shouldBeSelected) {
        subSection.children().each(function (index, value) {
          $('.' + $(value).data('guid')).addClass('selected');
        });
      } else {
        subSection.children().each(function (index, value) {
          $('.' + $(value).data('guid')).removeClass('selected');
        });
      }
    }

    var guid;
    var elementToCheck = $("#toc").find('.' + $(this).parent().data("guid")).parent();
    if(!shouldBeSelected && elementToCheck.hasClass('subChild')) {
      guid = $(elementToCheck).data('parentGuid');
      $('div[data-guid="' + guid + '"').each(function (index, value) {
        $(value).removeClass("selected");
      });
    }

    var hiddenInput = $('#' + selectionParent.data('guid') + '_input');
    if (hiddenInput.length > 0) {
      hiddenInput.prop('checked', shouldBeSelected);
      hiddenInput[0].dispatchEvent(new Event('change'));
    }

    var selectedBoxes = $('.selected.barTitle,.selected.mediumTitle');

    var guidCount = 0;
    selection = {};
    selectedGuids = {};

    if (!selectedBoxes.length) {
      //selectedGuids always contains the page guid when nothing has been selected
      //This allows you to add notes to the page, and to share the page when nothing is selected
      selectedGuids[bodyGuid] = getTitle(bodyGuid);
      $('#downloadButton').data('href', '/output/word/' + bodyGuid);
      $('#searchWithinSelected').hide();
      $('#searchWithinNonSelected').show();
      makeToolbarUnsticky();
    } else {
      selectedBoxes.each(function () {
        var $this = $(this);
        var guid = $this.data('guid');
        if (selectedGuids[guid]) {
          return;
        }
        // If this is a child checkbox and the parent is checked, there's no need to add it to selectedGuids
        if ($this.hasClass('child')) {
          var $parent = $('#' + $this.data('parentGuid') + '_title');
          if ($parent.length > 0 && $parent.hasClass('selected')) {
            return;
          }
        }
        guidCount++;
        selectedGuids[guid] = getTitle(guid);
      });

      $('#downloadButton').data('href', '/output/word/' + $.map(Object.keys(selectedGuids),
        function (v, i) {
          return i;
        }
      ).join());
      $('#searchWithinSelected').show();
      $('#searchWithinNonSelected').hide();

      if ((guidCount === 1 || shouldStickyHeader) && shouldBeSelected) {
        makeToolbarSticky();
      }
    }

    // Handle the disabled/enabled state of the Share and Add Note buttons
    var $shareButton = $('#shareButton');
    var $addNoteButton = $('#addNoteButton');
    if (guidCount > 1) {
      $shareButton.addClass('disabled');
      $shareButton.attr('title', 'You can only share one selection at a time');
      $addNoteButton.addClass('disabled');
      $addNoteButton.attr('title', 'You can only add a note to one selection at a time');
    } else {
      $shareButton.removeClass('disabled');
      $shareButton.attr('title', 'Share selected content');
      if (guidCount > 0 && $('#childContent').length === 0) {
        $addNoteButton.addClass('disabled');
        $addNoteButton.attr('title', 'You can not add notes to selections on pages without content');
      } else {
        $addNoteButton.removeClass('disabled');
        $addNoteButton.attr('title', 'Add note to the selected part of the Code');
      }
    }

    // Handle the disabled/enabled state of the Create Question button
    handleCreateQuestionState();
  };
  selectionClick(null);

  $('#content, #page-content').on('click', '.selectionBox', selectionClick);
  $('#content, #page-content').on('click', '.litem', function () {
    if (event.offsetX < 0) {
      var el = $(this);
      var id = el.attr('id');
      el.data('guid', id).addClass(id);
      selectionClick.call(this, true);
    }
  });

  var toolbarAnimationMs = 100;
  function makeToolbarSticky() {
    var scrollTop = $(window).scrollTop();
    if (toolbar.data('sticky') === true) {
      return;
    }

    var jqBodyTop = jqBody.position().top + menubarHeight;

    if (scrollTop > gps.data('naturalTop')) {
      toolbar.css({position: 'fixed', top: -45 + warningsHeight + jqBodyTop });
      gps.animate({
        top: (gpsHeight + warningsHeight + jqBodyTop - toolbarHeight) * -1
      }, toolbarAnimationMs, 'linear').promise().done(function () {
        if (toolbar.data('sticky')) {
          gps.css({position: 'relative', top: 0});
        }
      });
      toolbar.animate({top: warningsHeight + jqBodyTop }, toolbarAnimationMs, 'linear');
      titleArea.animate({top: toolbarHeight + warningsHeight + jqBodyTop + 2}, toolbarAnimationMs, 'linear');
    } else if (scrollTop > toolbar.data('naturalTop')) {
      toolbar.css({position: 'fixed', top: -45 + warningsHeight + jqBodyTop });
      toolbar.animate({top: warningsHeight + jqBodyTop}, toolbarAnimationMs, 'linear');
    }
    toolbar.data('sticky', true);
    //we just changed the height at the top of the document, the tables should reflow themselves
    bigTables.trigger('reflow');
  }

  function makeToolbarUnsticky() {
    var scrollTop = $(window).scrollTop();
    //Check if the selected guids is empty, or if it only contains the page guid
    var isEmptySelectedGuids = $.isEmptyObject(selectedGuids) || (Object.keys(selectedGuids).length === 1 && selectedGuids.hasOwnProperty(bodyGuid));
    if (toolbar.data('sticky') === false || !isEmptySelectedGuids || !$.isEmptyObject(selection)) {
      return;
    }

    var jqBodyTop = jqBody.position().top + menubarHeight;

    if (scrollTop > gps.data('naturalTop')) {
      gps.css({position: 'fixed', top: (gpsHeight + warningsHeight + jqBodyTop - toolbarHeight) * -1});
      gps.animate({top: warningsHeight + jqBodyTop}, toolbarAnimationMs, 'linear');
      toolbar.animate({top: -45 + warningsHeight + jqBodyTop}, toolbarAnimationMs, 'linear').promise().done(function () {
        if (!toolbar.data('sticky')) {
          toolbar.css({position: 'relative', top: 0});
        }
      });
      titleArea.animate({top: gpsHeight + warningsHeight + jqBodyTop + 2}, toolbarAnimationMs, 'linear');
    } else if (scrollTop > toolbar.data('naturalTop')) {
      toolbar.animate({top: -45 + warningsHeight + jqBodyTop}, toolbarAnimationMs, 'linear').promise().done(function () {
        if (!toolbar.data('sticky')) {
          toolbar.css({position: 'relative', top: 0});
        }
      });
    }
    toolbar.data('sticky', false);
    bigTables.trigger('reflow');
    //we just changed the height at the top of the document, the tables should reflow themselves
  }

  var selection = {};
  $(document).on('selectionchange', function () {
    selection = {};
    if (!jqBody[0].dataset.ia2Url) {
      return;
    }
    var selectedText = document.getSelection().toString();
    if (selectedText && selectedText.length > 0) {
      var paragraphSelector = ".para, .deftext, .ftpara";
      var startPara = $(document.getSelection().anchorNode).closest(paragraphSelector);
      var endPara = $(document.getSelection().focusNode).closest(paragraphSelector);
      if (startPara[0] && startPara[0] === endPara[0]) {
        var fullContext = startPara.clone().children().remove(".history").remove(".footnoteref").end().text().trim().replace(/\s+/g, " ");
        if (fullContext && fullContext.length > 0 && fullContext.indexOf(selectedText) >= 0) {
          var offset = fullContext.indexOf(selectedText);
          selection.selection = selectedText;
          selection.precontext = fullContext.substring(0, offset);
          selection.postcontext = fullContext.substring(offset + selectedText.length);
        } else {
          selection.selection = selectedText;
          selection.precontext = "";
          selection.postcontext = "";
        }

        var parentSelector = ".definition, .litem, .defitem, .section_content, .subarticle_content, .article_content, .chapter_content";
        var parent = startPara.closest(parentSelector);
        if (parent.hasClass("litem")) {
          selection.guid = parent[0].id;
        } else if (parent.hasClass("definition")) {
          selection.guid = parent[0].id;
        } else {
          selection.guid = parent[0].id.replace("_content", "");
        }
        handleCreateQuestionState();
        makeToolbarSticky();
        return;
      }
    }
    handleCreateQuestionState();
    makeToolbarUnsticky();
  });

  function handleCreateQuestionState() {
    var $createQuestionButton = $('#createQuestion');
    if (document.getSelection().toString().trim().length > 0) {
      if ($.isEmptyObject(selection)) {
        $createQuestionButton.addClass('disabled');
        $createQuestionButton.attr('title', 'You can only select content within a single paragraph, footnote, or definition');
      } else {
        $createQuestionButton.removeClass('disabled');
        $createQuestionButton.attr('title', 'Create Question');
      }
    } else if (Object.keys(selectedGuids).length > 1) {
      $createQuestionButton.addClass('disabled');
      $createQuestionButton.attr('title', 'You can only create a question for one selection at a time');
    } else {
      $createQuestionButton.removeClass('disabled');
      $createQuestionButton.attr('title', 'Create Question');
    }
  }

  $('#createQuestion').on('click', function (e) {
    e.preventDefault();
    if ($(this).hasClass('disabled')) {
      return;
    }
    var guid;
    if (document.getSelection().toString().trim().length > 0) {
      if ($.isEmptyObject(selection)) {
        $.showError({
          message: "This selection is not valid for creating a question.",
          message2: "Please select content within a single paragraph, footnote, or definition.",
          id: 'questionsInvalidSelectionError'
        });
        return;
      } else {
        guid = selection.guid;
      }
    } else {
      var guids = Object.keys(selectedGuids);
      if (guids.length !== 1) {
        $.showError({
          message: "Please select a single selection.",
          message2: "You can only create a question for one selection at a time.",
          id: 'questionsTooManyError'
        });
        return;
      }
      guid = guids[0];
    }
    createManuscript(guid);
  });

  function createManuscript(guid) {
    var url = "/content/manuscript?guid=" + guid;
    if (selection.selection) {
      url += "&selection=" + encodeURIComponent(selection.selection);
    }
    $.ajax({
      type: "GET",
      url: url,
      success: function (manuscript) {
        manuscript.selection = selection.selection;
        manuscript.precontext = selection.precontext;
        manuscript.postcontext = selection.postcontext;
        createAndOpenQuestion(manuscript);
        selectedGuids = {};
        selection = {};
        $(".selected.barTitle,.selected.mediumTitle").removeClass("selected");
        document.getSelection().empty();
      },
      error: function () {
        $.showError({
          message: "Something went wrong while creating the question",
          id: 'questionCreateError'
        });
      }
    });
  }

  function createAndOpenQuestion(manuscript) {
    var ia2Url = jqBody[0].dataset.ia2Url;
    $.ajax({
      type: "POST",
      url: ia2Url + "/data/newContent",
      data: JSON.stringify(manuscript),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      success: function (id) {
        window.open(ia2Url + "/create?id=" + id,"newIa2Question");
      },
      error: function () {
        $.showError({
          message: "Something went wrong while creating the question",
          id: 'questionCreateError'
        });
      }
    });
  }

  $('#downloadButton').on('click', function (e) {
    e.preventDefault();
    var wordEnabled = jqBody.data('wordPermission');
    var pdfEnabled = jqBody.data('pdfPermission');
    var wordUserRequired = jqBody.data('wordLoginRequired');
    var pdfUserRequired = jqBody.data('pdfLoginRequired');
    var downloadModal = $('<div class="downloadModal"></div>');
    var downloadItems = $('<div class="followItems"></div>');
    downloadModal.append(downloadItems);

    $.each(Object.values(selectedGuids), function (index, title) {
      downloadItems.append($('<div class="followItem"></div>').text(title));
    });

    var pdfDownload = $('<div class="modalErrorButton fileDownloadSelection" id="pdfDownload"><div>PDF</div></div>')
      .data('type', 'pdf');
    if (pdfEnabled) {
      pdfDownload.addClass('modalClose').data('disabled', false);
    } else {
      pdfDownload.addClass('disabled').data('disabled', true);
      if (user) {
        pdfDownload.data('disabledMessage',
          'Download to PDF is not available for this code. Please contact <a href="mailto:customerservice@generalcode.com">General Code Customer Service</a> to gain access to this download format.');
      }
      else {
        if (pdfUserRequired) {
          pdfDownload.data('disabledMessage',
            'Download to PDF is a feature reserved for logged-in municipal users or users of the <a href="/subscription/' + bodyCustId + '">eCode360 Subscription Service</a>. Please log in from the <a href="/login/' + bodyCustId + '">login page</a>');
        }
        else {
          pdfDownload.data('disabledMessage',
            'Download to PDF is not a feature enabled for this municipality’s eCode. However, users of the <a href="/subscription/' + bodyCustId + '">eCode360 Subscription Service</a> can still gain access to this download format.');
        }
      }
    }
    downloadModal.append(pdfDownload);

    var wordDownload = $('<div class="modalErrorButton fileDownloadSelection" id="wordDownload"><div>Word</div></div>').data('type', 'word');
    if (wordEnabled) {
      wordDownload.data('disabled', false).addClass('modalClose');
    } else {
      wordDownload.data('disabled', true).addClass('disabled');
      if (user) {
        wordDownload.data('disabledMessage',
          'Download to Word is not available for this code. Please contact <a href="mailto:customerservice@generalcode.com">General Code Customer Service</a> to gain access to this download format.');
      }
      else {
        if (wordUserRequired) {
            wordDownload.data('disabledMessage',
              'Download to Word is a feature reserved for logged-in municipal users or users of the <a href="/subscription/' + bodyCustId + '">eCode360 Subscription Service</a>. Please log in from the <a href="/login/' + bodyCustId + '">login page</a>');
        }
        else {
          wordDownload.data('disabledMessage',
            'Download to Word is not a feature enabled for this municipality’s eCode. However, users of the <a href="/subscription/' + bodyCustId + '">eCode360 Subscription Service</a> can still gain access to this download format.');
        }
      }
    }
    if (!codeIdIsNotCurrentCodeId) {
      downloadModal.append(wordDownload);
    }

    downloadModal.append($('<div id="downloadDisabledMessage"></div>').css({display: 'none', 'margin-top': '20px'}));

    $.modal({
      modalClass: 'downloadModal',
      title: {text: 'Download the selected portions of your ' + (sidebarLabel || 'Code') + ':', titleClass: 'downloadModalTitle'},
      content: downloadModal
    });

  });

  emailTool.click(function () {

    var guids  = Object.keys(selectedGuids);
    if (!emailTool.data('default-text')) {
      emailTool.data('default-text', emailTool.attr('href'));
    }
    emailTool.attr('href', emailTool.data('default-text'));
    var archiveBodyString = "";
    if (codeIdIsNotCurrentCodeId) {
      archiveBodyString = "Archive " + jqBody.data('codeDate') + "%0A";
    }

    var emailBody = "mailto:" +
      "?subject=" + encodeURIComponent(jqBody.data('customerName')) +
      "&body=" + encodeURIComponent(jqBody.data('customerName')) + " Code%0A" +
      archiveBodyString + "%0A";

    $.each(guids, function (index, guid) {
      var title = selectedGuids[guid];
      emailBody += (chapterString && chapterString !== title ? encodeURIComponent(chapterString) + "%0A" : "") +
        encodeURIComponent(title) + "%0A" +
        jqBody.data("urlSchema") + "://" + window.location.hostname + "/" + codePrefix + guid + "%0A%0A";
    });
    emailTool.attr('href', emailBody);
    eventService.logEvent(EventCategory.SHARE, EventName.SHARE_CONTENT_EMAIL, bodyCustId, {type: 'email', guids: guids});
  });

  $('#shareButton,.litem_number,.defitem_number').on('click', function (e) {
    e.preventDefault();
    if (codeIdIsNotCurrentCodeId || $(this).hasClass('disabled')) {
      return;
    }
    var shareModal = $('<div class="shareModal">');
    var followItems = $('<div class="followItems"></div>');
    shareModal.append(followItems);

    var guid;
    if ($(this).is('.litem_number, .defitem_number')) {
      guid = $(this).parent().attr('id');
    } else {
      var guids = Object.keys(selectedGuids);
      if (guids.length !== 1) {
        $.showError({
          message: "Please select a single selection.",
          message2: "You can only share one selection at a time.",
          id: 'noteTooManyError'
        });
        return;
      }
      guid = guids[0];
    }
    var shareTitle = getTitle(guid);
    var shareText = "";
    var shareTitleText = "";
    followItems.append($('<div class="followItem"></div>').text(shareTitle));
    shareText += (chapterString ? encodeURIComponent(chapterString) + "%0A" : "") +
      encodeURIComponent(shareTitle) + "%0A" +
      jqBody.data("urlSchema") + "://" + window.location.hostname + "/" + codePrefix + guid + "%0A%0A";
    shareTitleText += shareTitle;
    var shareUrl = jqBody.data("urlSchema") + '://' + window.location.host + '/' + guid;
    shareModal.append('<div class="shareTitle">How would you like to share?</div>');
    shareModal.append('<a href="https://www.facebook.com/dialog/share?app_id=' + jqBody.data(
      'facebookAppId') + '&display=page&href=' + encodeURIComponent(shareUrl) + "&redirect_uri=" + encodeURIComponent(
      window.location) + '" class="modalErrorButton modalClose modalLink shareSelection" target="_blank" id="shareViaFacebook" target="_blank">Facebook</a>');

    if (!shareText && emailTool.data('default-text')) {
      shareText = emailTool.data('default-text').substring(emailTool.data('default-text').indexOf('body=') + 5);
      if (shareText.indexOf(customerName) === -1) {
        shareText = customerName + "%0A" + shareText;
      }
    }

    shareModal.append('<a class="modalErrorButton modalClose modalLink shareSelection" id="shareViaTwitter" href="https://twitter.com/intent/tweet?text=' + shareText + '" target="_blank">Twitter</a>');

    var paramString = 'guid=' + guid;
    var printUrl = jqBody.data('urlSchema') + '://' + window.location.host + '/print/' + (codeIdIsNotCurrentCodeId ?
      jqBody.data('codeId') : bodyCustId) + '?' + paramString;
    shareModal.append('<a class="modalErrorButton modalClose modalLink shareSelection" id="shareViaPrint" href="'+ printUrl +'" + target="_blank">Print</a>');

    var archiveBodyString = "";
    if (codeIdIsNotCurrentCodeId) {
      archiveBodyString = "Archive " + jqBody.data('codeDate') + "%0A";
    }
    var emailBody = "mailto:" +
      "?subject=" + encodeURIComponent(customerName) +
      "&body=" + encodeURIComponent(customerName) + " Code%0A" +
      archiveBodyString + "%0A";

    emailBody += (chapterString && chapterString !== shareTitle ? encodeURIComponent(chapterString) + "%0A" : "") +
      encodeURIComponent(shareTitle) + "%0A" +
      jqBody.data("urlSchema") + "://" + window.location.hostname + "/" + codePrefix + guid + "%0A%0A";
    shareModal.append('<a class="modalErrorButton modalClose modalLink shareSelection" id="shareViaEmail" href="'+ emailBody +'" + target="_blank">Email</a>');

    shareModal.append('<div id="linkBox">' +
      '<input type="text" id="linkCopy" readonly value="' + shareUrl + '"/>' +
      '<button id="linkCopyButton">Copy link</button>' +
      '<div>Ctrl+V to paste it into your desired location.</div>' +
      '</div>');
    shareModal.find('#linkCopyButton').on('click', function () {
      linkBox[0].focus();
      linkBox[0].setSelectionRange(0, linkBox.val().length);
      document.execCommand("copy");
    });

    var shareHtml = '<a href="' + shareUrl + '" target="{TARGET}">' + shareTitleText + '</a>';
    shareModal.append('<div id="embedBox">' +
      '<div class="shareEmbedTitle">Embed</div>' +
      '<div><input type="checkbox" id="embedInNewWindow"/> Open in New Window</div>' +
      '<div>' +
      '<input type="text" id="embedCopy" readonly value="' + shareHtml.replace(/{TARGET}/, "_self").replace(/"/g, "&quot;") + '"/>' +
      '<button id="embedCopyButton">Copy html</button>' +
      '<div>Ctrl+V to paste it into your desired location.</div>' +
      '</div>' +
      '</div>');
    shareModal.find('#embedCopyButton').on('click', function () {
      var embedBox = $('#embedCopy');
      embedBox[0].focus();
      embedBox[0].setSelectionRange(0, embedBox.val().length);
      document.execCommand("copy");
    });
    shareModal.find('#embedInNewWindow').on('click', function () {
      $('#embedCopy').val(shareHtml.replace(/{TARGET}/, this.checked ? "_blank" : "_self"));
    });

    $.modal({
      modalClass: 'shareModal',
      title: {text: 'You are sharing:', titleClass: 'shareModalTitle'},
      content: shareModal
    });

    var linkBox = $('#linkCopy');
    linkBox.select();
    linkBox.on('click', function () {
      linkBox.select();
    });
    $('#shareViaFacebook').click(function () {
      eventService.logEvent(EventCategory.SHARE, EventName.SHARE_CONTENT_FACEBOOK, bodyCustId, {type: 'facebook', guids: [guid]});
    });
    $('#shareViaTwitter').click(function () {
      eventService.logEvent(EventCategory.SHARE, EventName.SHARE_CONTENT_TWITTER, bodyCustId, {type: 'twitter', guids: [guid]});
    });
  });

  $('#followButton').on('click', function (e) {
    e.preventDefault();
    var followModal = $('<div class="followModalContainer">');
    var followItems = $('<div class="followItems"></div>');
    followModal.append(followItems);

    var guids = Object.keys(selectedGuids);

    $.each(Object.values(selectedGuids), function (index, title) {
      followItems.append($('<div class="followItem"></div>').text(title));
    });

    var emailBox = $('<div id="emailBox"><input type="email" id="followEmailAddress" aria-label="Your email address" placeholder="Your email address" ' +
      (user ? 'value="' + user + '"' : '') + '/><button id="followEmailButton">Sign Up</button><div id="followEmailWorking">Signing you up...</div></div>');
    followModal.append(emailBox);
    var notificationDisabled = false;
    var requestNotification = function () {
      if (!notificationDisabled) {
        var emailAddress = followModal.find('#followEmailAddress').val();
        if (emailAddress.match(/[^@]+@[^@]+\.[^@]+/)) {
          notificationDisabled = true;
          emailBox.addClass('working');

          $.ajax({
            email: emailAddress,
            url: '/user/notification/create',
            type: 'POST',
            data: {
              email: followModal.find('#followEmailAddress').val(),
              guids: guids
            },
            success: function () {
              notificationDisabled = false;
              emailBox.removeClass('working');
              $.closeModal();
              $.showError({
                title: "eAlert Subscription Request Received",
                message: "Please check your " + emailAddress + " email account to confirm your eAlert subscription request"
              });
            },
            error: function (got) {
              notificationDisabled = false;
              emailBox.removeClass('working');
              console.debug(got);
              $.showError(
                {message: "Something went wrong. Please try again. If you continue to have problems, please contact General Code's Client Services Team at <a class='xref' href='mailto:ecodehelp@generalcode.com'>eCodehelp@generalcode.com</a>"});
            }
          });
        } else {
          $.showError({message: "Please try again using a valid email address"});
        }
      }
    };
    followModal.find('#followEmailButton').on('click', requestNotification);
    followModal.find('#followEmailAddress').on('keydown', function (e) {
      //enter pressed
      if (e.which === 13) {
        requestNotification(e);
      }
    });

    $.modal({
      modalClass: 'followModal',
      title: {text: 'Sign up to receive automatic email alerts (eAlerts) when the following Code content is updated:'},
      content: followModal
    });
  });

  $('#unsubscribeAll').on('click', function (e) {
    $('.notificationItem').prop("checked", true);
    e.preventDefault();
  });

  jqBody.on('click', '.fileDownloadSelection', function () {
    if ($(this).data('disabled')) {
      $('#downloadDisabledMessage').show().html($(this).data('disabledMessage'));
      return;
    }
    var type = $(this).data('type');

    var url = $('#downloadButton').data('href');
    var guids = Object.keys(selectedGuids);
    if (guids.length) {
      url = '/output/word/' + guids.join();
    }
    url = url.replace('/word/', '/' + type + '/');
    if (codeIdIsNotCurrentCodeId) {
      url = url + "/" + jqBody.data('codeId');
    }

    var waiting = $('<div id="waiting"><div id="waitingTitle">Generating your document.  Please wait...</div><img src="/images/Spinner.gif"></div>');

    var checkCount = 0;
    $.getJSON(url).done(function (data) {
      var statusNumber = data;
      var checkStatus = function () {
        $.getJSON('/output/status/' + statusNumber).done(function (statusData) {
          if (statusData.state === 'INITIALIZED') {
            showOverlay();
            window.setTimeout(checkStatus, 100 + (30000 * (checkCount / (checkCount + 1000))));
            checkCount++;
          } else {
            waiting.remove();
            hideOverlay();
            if (statusData.state === "FINISHED") {
              document.location.pathname = '/output/get/' + statusNumber;
            }
          }

        }).fail(function () {
          $.showError({message: "Failed to generate document.  Please try again."});
        });
      };
      checkStatus();
    }).fail(function () {
      $.showError({message: "Failed to generate document.  Please try again."});
    });

    showOverlay();
    jqBody.append(waiting);
  });

  $('#printButton').on('click', function (e) {

    var paramString = '';
    var selectedBoxes = $('.selected.barTitle,.selected.mediumTitle');
    var guids = [];
    selectedBoxes.each(function(index, selectedBox) {
      var guid = $(selectedBox).data('guid');
      if (guids.indexOf(guid) === -1) {
        guids.push(guid);
      }
    });
    if (!guids.length) {
      paramString = 'guid=' + bodyGuid;
    } else {
      paramString = 'guid=' + guids.join();
    }
    eventService.logEvent(EventCategory.PRINT, EventName.PRINT_CONTENT, bodyCustId, {type: 'ecode', guids: guids});

    var loc = window.location;
    window.open(jqBody.data('urlSchema') + '://' + loc.host + '/print/' + (codeIdIsNotCurrentCodeId ?
      jqBody.data('codeId') : bodyCustId) + '?' + paramString);
    e.preventDefault();
  });

  var goPrev = function (e) {
    if ($('.lightbox').length === 0 && (e.type !== 'swiperight' || $(e.target).parents('table').length === 0 || $(e.target)
      .parents('table')
      .parent()
      .css('overflow-x') !== 'auto')) {
      var previousGuid = jqBody.data('previous-guid');
      if (previousGuid) {
        if (jqBody.data('currentCodeId') !== jqBody.data('codeId')) {
          previousGuid = jqBody.data('codeId') + '/' + previousGuid;
        }
        e.preventDefault();
        window.location = '/' + previousGuid;
      }
    }
  };

  var goNext = function (e) {
    if ($('.lightbox').length === 0 && (e.type !== 'swipeleft' || $(e.target).parents('table').length === 0 || $(e.target)
      .parents('table')
      .parent()
      .css('overflow-x') !== 'auto')) {
      var nextGuid = jqBody.data('next-guid');
      if (nextGuid) {
        if (jqBody.data('currentCodeId') !== jqBody.data('codeId')) {
          nextGuid = jqBody.data('codeId') + '/' + nextGuid;
        }
        e.preventDefault();
        window.location = '/' + nextGuid;
      }
    }
  };

  $(document).on('keydown', function (e) {
    if (e.shiftKey && e.ctrlKey && e.altKey) {
      switch (e.which) {
        case 76:
        //Jump between servers at this url, default to live environment
        var where = prompt("Where to?", "");
        if (where) {
          where += ".";
        }
        where += "ecode360.com:443";
        window.location.host = where;
        return;
      case 71:
        //Jump between servers at this url, default to live environment
        var which = prompt("Which?", "");
        if (which) {
          window.location = "/" + which;
          return;
        }
        break;
      case 74:
        if (e.shiftKey && e.ctrlKey && e.altKey) {
          $(document.body).toggleClass('upsideDown');
        }
        break;
      case 84:
        if (e.shiftKey && e.ctrlKey && e.altKey) {
          $(document.body).toggleClass('teal');
        }
        break;
      default:
        //just continue to next switch statement
        break;
    }
  }
    if (!$('.modal:visible').length && !$(document.activeElement).is('input') && !document.activeElement.isContentEditable) {
      switch (e.which) {
        case 37: //left
          goPrev(e);
          break;
        case 39: //right
          goNext(e);
          break;
        default:
          return;
      }
    }
  });

  $('#gpsPrev').on('click', goPrev);
  $('#gpsNext').on('click', goNext);

  if (window.hasOwnProperty && window.hasOwnProperty('Modernizr')) {
    Modernizr.load({
      test: Modernizr.touch,
      yep: '/js/jquery.mobile.custom-min.js',
      complete: function () {
        if (typeof $.event.special.swipe === 'function') {
          $.event.special.swipe.durationThreshold = 500;
          $.event.special.swipe.verticalDistanceThreshold = 40;
          $.event.special.swipe.horizontalDistanceThreshold = screen.availWidth / 2;
          $(window).on('swipeleft.nav', goNext);
          $(window).on('swiperight.nav', goPrev);
        }
      }
    });
  }

  window.addEventListener("beforeprint", function () {
    eventService.logEvent(EventCategory.PRINT, EventName.PRINT_CONTENT, bodyCustId, {type: 'browser', guids: [bodyGuid]});
  });

  window.addEventListener("keydown", function(event) {
    if (event && (event.key === "PageUp" || event.key === "PageDown")) {
      event.preventDefault();
      var scrollDistance = $(window).height() - (
        jqBody.position().top +
        $('#menubarContainer').outerHeight() +
        gps.outerHeight() +
        toolbar.outerHeight() +
        footer.outerHeight()
      );
      if (event.key === "PageUp") {
        window.scrollTo(window.pageXOffset, window.pageYOffset - scrollDistance);
      } else if (event.key === "PageDown") {
        window.scrollTo(window.pageXOffset, window.pageYOffset + scrollDistance);
      }
    }
  });
});

$(document).ready(function () {
  var jqBody = $('body');

  var showOverlay = function () {
    jqBody.addClass("showOverlay");
  };
  var hideOverlay = function () {
    jqBody.removeClass("showOverlay");
  };

  $('#text').on('click', '.lightboxClose', function () {
    hideOverlay();
    if ($.floatThead) {
      $(this).parents('.lightbox').find('table').each(function() {
        $(this).floatThead('destroy');
      });
    }
    $(this).parents('.lightbox').removeClass('lightbox').scrollTop(0).scrollLeft(0).find('.tableScrollWrapper').scrollTop(0).scrollLeft(0);
  });

  $('#overlay').on('click.lightbox', function () {
    hideOverlay();
    $('.modal').each(function () {
      $(this).find(".modalClose").click();
    });
    $('.lightbox').each(function () {
      if ($.floatThead) {
        $(this).find('table').each(function() {
          $(this).floatThead('destroy');
        });
      }
      $(this).removeClass('lightbox').scrollTop(0).scrollLeft(0).find('.tableScrollWrapper').scrollTop(0).scrollLeft(0);
    });
  });

  /** Polyfill for isNaN needed by IE */
  Number.isNaN = Number.isNaN || function(value) {
    return value !== value;
  };
  
  /**
   * Take the result of parseFloat and return 0 if it isn't a number or is NaN
   */
  var orZeroParsed = function(num) {
    return (num === undefined || num === null || Number.isNaN(num)) ? 0 : num;
  };

  /**
   * Take a string and return the number it starts with or 0 if one can't be found
   */
  var orZero = function(num) {
    return orZeroParsed(parseFloat(num));
  };

  $('#text').on('click', '.landTable:not(.lightbox)', function () {
    var tableContainer = $(this);
    tableContainer.addClass('lightbox');
    if (!tableContainer.hasClass('modalFramework')) {
      var title = tableContainer.children('.tableTitle');
      title.append("<div class='lightboxClose' role='button' aria-label='Close'>&times;</div>");
      tableContainer.addClass('modalFramework');
    }
    showOverlay();

    if ($.floatThead ) {
      setTimeout(function () {
        if (tableContainer.find('table').length > 0) {
          tableContainer.find('table').each(function () {
            var $theadTable = $(this);
            $theadTable.floatThead({
              responsiveContainer: function ($table) {
                return $table.parents('.tableScrollWrapper');
              },
              top: function ($table) {
                var top = orZero($table.parents('.codeTable').position().top) + orZero($table.css('border-top'));
                if ($table.parents('.codeTable').hasClass('lightbox')) {
                  top += $table.parents('.codeTable').find('.tableTitle').outerHeight();
                }
                return top;
              },
              bottom: 40,
              zIndex: 1,
              forceScrollBar: true,
              copyAttributes: true,
              autoReflow: true
            });
            setTimeout(function () {
              $theadTable.trigger('reflow');
            }, 1000);
          });
        }
      }, 1000);
    }
  });
  $('#menubarExpand').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    jqBody.toggleClass('expandedMenu');
    if (jqBody.hasClass('expandedMenu')) {
      jqBody.one('click.expand', function () {
        jqBody.removeClass('expandedMenu');
      });
    } else {
      jqBody.off('click.expand');
    }
  });

  $('.litem_content>*:not(.level), .defitem_content>*:not(.deflevel)').hover(
    function () {
      $(this).parent().prev().addClass('hovered');
    },
    function () {
      $(this).parent().prev().removeClass('hovered');
    }
  );
  $('#topArea').on('webkitAnimationEnd onanimationend msAnimationEnd animationend', function () {setTimeout(function () {$('table').trigger('reflow');}, 20);});

});

function EventService() {
  this.logEvent = function (eventCategory, eventName, custId, eventInfo) {
    var eventInfoString = JSON.stringify(eventInfo);
    $.post('/event', {eventName: eventName, custId: custId, eventInfo: eventInfoString});
    window.generalcode.logAnalyticsEvent(eventName, {
      "event_category": (eventCategory ? eventCategory : "generic"),
      "event_label": custId,
      "event_info": eventInfoString
    });
  };
}

var EventCategory = {
  INDEX: "Index",
  PRINT: "Print",
  SHARE: "Share",
  SUBSCRIPTION: "Subscription"
};

var EventName = {
  INDEX_USE: "IndexUse",
  PRINT_CONTENT: "PrintContent",
  SHARE_CONTENT_FACEBOOK: "ShareContentFacebook",
  SHARE_CONTENT_TWITTER: "ShareContentTwitter",
  SHARE_CONTENT_EMAIL: "ShareContentEmail",
  SUBSCRIPTION_QUESTION_ASKED: "SubscriptionQuestionAsked",
  SUBSCRIPTION_QUESTION_DECLINED: "SubscriptionQuestionDeclined"
};

window.generalcode.refreshNoteLocations = function() {
  $('.notesTitle, #notesPage .notes').each(function() {
    var notes = $(this).find('.noteContainer');
    if (notes[0]) {
      $(this).show();
    } else {
      $(this).hide();
    }
  });
  if (!$('.noteContainer')[0]) {
    $('#noNotesMessage').removeClass('hide');
  }
};


